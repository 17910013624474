// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = (account) => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();

      let totalStaked = await store
        .getState()
        .blockchain.stakingContract.methods.getStakedTokens(account)
        .call()
        .then((res) => {
          console.log(res);
          return res.length;
        });

      let balance = await store
        .getState()
        .blockchain.stakingContract.methods.stakers(account)
        .call()
        .then((res) => {
          return res.balance;
        });

      let rewardsReleased = await store
        .getState()
        .blockchain.stakingContract.methods.stakers(account)
        .call()
        .then((res) => {
          return res.rewardsReleased;
        });

      let rarities = await store
        .getState()
        .blockchain.stakingContract.methods.getStakedRarities(account)
        .call()
        .then((res) => {
          return res;
        });

      let stakeTimes = await store
        .getState()
        .blockchain.stakingContract.methods.getStakedTimes(account)
        .call()
        .then((res) => {
          return res;
        });

      dispatch(
        fetchDataSuccess({
          totalSupply,
          totalStaked,
          balance,
          rewardsReleased,
          rarities,
          stakeTimes,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
