import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import DtPopup from "../../../../components/DtPopup";
import ChPopup from "../../../../components/ChPopup";
import DescriptionPopup from "../../../../components/DescriptionPopup";
import StatsPopup from "../../../../components/StatsPopup";
import ring from "../../../../media/gif/ring.gif";
import { usePopperTooltip } from "react-popper-tooltip";
import "react-popper-tooltip/dist/styles.css";
import { fetchData } from "../../../../redux/data/dataActions";

import {
  setpopupAu,
  setpopupBg,
  setpopupCh,
  setpopupDt,
  setpopupDes,
  setpopupStats,
} from "../../../../redux/actions/styles";
import AuPopup from "../../../../components/AuPopup";
import ButtonPrimary from "../../../../components/button/ButtonPrimary";
import ripple from "ripple-effects";
import {
  removecharacter,
  setaddBalance,
  setMenuOpen,
} from "../../../../redux/actions/data";

const Characters = () => {
  const dispatch = useDispatch();
  const { popupCh, popupDt, popupAu, popupDes, popupStats } = useSelector(
    (state) => state.styles
  );
  const { roomData, currentRoom, waifus, menuOpen } = useSelector(
    (state) => state.data
  );
  const [selectedOption, setselectedOption] = useState(null);
  const { balance, totalStaked, rewardsReleased, rarities, stakeTimes } =
    useSelector((state) => state.blockchainData);
  const [audioToggle, setaudioToggle] = useState(0);
  const [animateChar, setanimateChar] = useState(null);
  const blockchain = useSelector((state) => state.blockchain);
  const { balances } = useSelector((state) => state.data);
  const [showExtra, setshowExtra] = useState(false);

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip();

  const { char1, char2, char3, char4 } = roomData[currentRoom];

  let audioRef = useRef(new Audio());
  const rippleRef = useRef(null);

  const unstake = (selectedOption, characterId) => {
    console.log("Character", selectedOption, characterId);
    blockchain.stakingContract.methods
      .unstake(characterId)
      .send({
        gasLimit: String(300000),
        from: blockchain.account,
      })
      .then(() => {
        setMenuOpen(null);
        dispatch(removecharacter(currentRoom, selectedOption));
        getData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const emergencyUnstake = (selectedOption, characterId) => {
    console.log("Character", selectedOption, characterId);
    blockchain.stakingContract.methods
      .emergencyUnstake(characterId)
      .send({
        gasLimit: String(300000),
        from: blockchain.account,
      })
      .then(() => {
        setMenuOpen(null);
        dispatch(removecharacter(currentRoom, selectedOption));
        getData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateReward = () => {
    let reward = 0;
    let totalReward = 0;
    let date = new Date();
    let now = date.getTime();

    for (let i = 0; i < rarities.length; i++) {
      const diffMiliSeconds = now - stakeTimes[i];
      const diffSeconds = diffMiliSeconds / 1000;
      const diffMinutes = (diffSeconds / 60).toFixed(0);

      console.log(rarities[i], diffMinutes);

      if ("C" === rarities[i] || "Co" === rarities[i].slice(0, 2)) {
        totalReward = parseInt(diffMinutes) * 1 * 0.01;
      } else if ("UC" === rarities[i] || "Unc" === rarities[i].slice(0, 3)) {
        totalReward = (((parseInt(diffMinutes) * 3) / 2) * 0.01).toFixed(2);
      } else if ("R" === rarities[i] || "Rare" === rarities[i].slice(0, 4)) {
        totalReward = (((parseInt(diffMinutes) * 27) / 10) * 0.01).toFixed(2);
      } else if ("SR" === rarities[i]) {
        totalReward = (((parseInt(diffMinutes) * 11) / 2) * 0.01).toFixed(2);
      } else if ("SSR" === rarities[i]) {
        totalReward = (parseInt(diffMinutes) * 15 * 0.01).toFixed(2);
      } else if ("UR" === rarities[i]) {
        totalReward = (parseInt(diffMinutes) * 45 * 0.01).toFixed(2);
      }

      reward += parseInt(totalReward);
    }
    console.log("reward", reward * 100000000000000 - rewardsReleased);

    blockchain.stakingContract.methods
      .updateReward(
        blockchain.account,
        String(reward * 100000000000000 - rewardsReleased)
      )
      .send({
        gasLimit: String(1000000),
        from: blockchain.account,
      })
      .then(() => {
        getData();
        let date = new Date();
        dispatch(
          setaddBalance(
            reward * 100000000000000 - rewardsReleased,
            date.getTime()
          )
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.stakingContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const calculateCoinBalance = () => {
    if (
      new Date(balances[balances.length - 1].date).getDay() ===
      new Date().getDay()
    ) {
      const startDate = new Date(balances[balances.length - 1].date);
      console.log(startDate);
      const timeDiff = Math.abs(Date.now() - startDate);
      const diffHrs = Math.floor((timeDiff / 36e5) % 24);
      console.log(diffHrs);

      if (diffHrs < 4) {
        return 1;
      } else if (diffHrs > 4 && diffHrs < 8) {
        return 2;
      } else if (diffHrs > 8 && diffHrs < 12) {
        return 3;
      } else if (diffHrs > 12 && diffHrs < 16) {
        return 4;
      } else if (diffHrs > 16 && diffHrs < 20) {
        return 5;
      } else if (diffHrs > 20 && diffHrs <= 24) {
        return 6;
      }
    } else {
      return 6;
    }
  };

  function playAudio(charNo) {
    audioRef.current.pause();
    if (roomData[currentRoom][`char${charNo}`].audio) {
      audioRef.current = new Audio(
        roomData[currentRoom][`char${charNo}`].audio.audios[audioToggle]
      );

      audioRef.current.oncanplay = function () {
        if (
          audioToggle ===
          roomData[currentRoom][`char${charNo}`].audio.audios.length - 1
        ) {
          setaudioToggle(0);
        } else {
          setaudioToggle(audioToggle + 1);
        }
      };
    }
  }

  function animate(charNo) {
    roomData[currentRoom][`char${charNo}`].audio && setanimateChar(charNo);
  }

  useEffect(() => {
    ripple(rippleRef.current);
    getData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    audioRef.current.play();
  }, [audioToggle]);

  return (
    <div className="characters ">
      {menuOpen && (
        <div
          className="bg-menu-2"
          onClick={() => dispatch(setMenuOpen(null))}
        />
      )}
      {!roomData[currentRoom].background &&
        !char1.image &&
        !char2.image &&
        !char3.image &&
        !char4.image &&
        !waifus && (
          <ButtonPrimary
            text="Add Background"
            className="ch-bg"
            onClick={() => dispatch(setpopupBg(true))}
          />
        )}
      <div className="ch1 ">
        {!char1.image ? (
          !waifus ? null : (
            <div className="placeholder">
              <i
                className="fa-solid fa-plus add"
                onClick={() => {
                  setselectedOption("1");
                  dispatch(setpopupCh(true));
                }}
              ></i>
              <img className="ring" src={ring} alt="ring" />
            </div>
          )
        ) : (
          <div className="char1 ">
            <div className="header">
              <div className="heart w-8 h-8">
                <img
                  src={`/hearts/${char1.hearts}.png`}
                  alt="ring"
                  className=" object-contain"
                />
              </div>
              <p>{char1.name}</p>
              <i
                className="fa-solid fa-align-justify"
                onClick={() => dispatch(setMenuOpen("ch1"))}
              ></i>

              {menuOpen === "ch1" && (
                <div className="menu">
                  <div
                    className="item"
                    onClick={() => {
                      setselectedOption("1");
                      dispatch(setpopupDes(true));
                      dispatch(setMenuOpen(null));
                    }}
                  >
                    Waifu Description
                  </div>
                  <div
                    className="item"
                    onClick={() => {
                      setselectedOption("1");
                      dispatch(setpopupStats(true));
                      dispatch(setMenuOpen(null));
                    }}
                  >
                    Statistics
                  </div>
                  <div
                    className="item"
                    onMouseOver={() => {
                      setshowExtra(!showExtra);
                    }}
                  >
                    Edit Waifu
                  </div>

                  {showExtra ? (
                    <>
                      {" "}
                      <div
                        className="item ml-2"
                        onClick={() => {
                          setselectedOption("1");
                          dispatch(setpopupDt(true));
                          dispatch(setMenuOpen(null));
                        }}
                      >
                        <small> Edit Description</small>
                      </div>
                      <div
                        className="item ml-2"
                        onClick={() => {
                          setselectedOption("1");
                          dispatch(setpopupAu(true));
                          dispatch(setMenuOpen(null));
                        }}
                      >
                        <small> Change Audio</small>
                      </div>
                    </>
                  ) : null}

                  <div
                    className="item"
                    onClick={() => {
                      emergencyUnstake("1", char1.id);
                      setselectedOption("1");
                      dispatch(setpopupCh(true));
                      dispatch(setMenuOpen(null));
                    }}
                  >
                    Change Waifu
                  </div>
                  <div
                    className="item"
                    onClick={() => {
                      unstake("1", char1.id);
                    }}
                  >
                    Remove Waifu
                  </div>
                </div>
              )}
            </div>

            <img
              src={`/assets/${char1.id}.png`}
              alt="char"
              className={animateChar === "1" ? "animate-left" : ""}
              onClick={() => {
                animate("1");
                playAudio("1");
              }}
            />
          </div>
        )}
      </div>
      <div className="ch2">
        {!char2.image ? (
          !waifus ? null : (
            <div className="placeholder">
              <i
                className="fa-solid fa-plus add"
                onClick={() => {
                  setselectedOption("2");
                  dispatch(setpopupCh(true));
                }}
              ></i>
              <img className="ring" src={ring} alt="ring" />
            </div>
          )
        ) : (
          <div className="char2">
            <div className="header">
              <div className="heart  w-8 h-8">
                <img src={`/hearts/${char2.hearts}.png`} alt="ring" />
              </div>
              <p>{char2.name}</p>
              <i
                className="fa-solid fa-align-justify"
                onClick={() => dispatch(setMenuOpen("ch2"))}
              ></i>
              {menuOpen === "ch2" && (
                <div className="menu">
                  <div
                    className="item"
                    onClick={() => {
                      setselectedOption("2");
                      dispatch(setpopupDes(true));
                      dispatch(setMenuOpen(null));
                    }}
                  >
                    Waifu Description
                  </div>
                  <div
                    className="item"
                    onClick={() => {
                      setselectedOption("2");
                      dispatch(setpopupStats(true));
                      dispatch(setMenuOpen(null));
                    }}
                  >
                    Statistics
                  </div>
                  <div
                    className="item"
                    onMouseOver={() => {
                      setshowExtra(!showExtra);
                    }}
                  >
                    Edit Waifu
                  </div>

                  {showExtra ? (
                    <>
                      {" "}
                      <div
                        className="item ml-2"
                        onClick={() => {
                          setselectedOption("2");
                          dispatch(setpopupDt(true));
                          dispatch(setMenuOpen(null));
                        }}
                      >
                        <small> Edit Description</small>
                      </div>
                      <div
                        className="item ml-2"
                        onClick={() => {
                          setselectedOption("2");
                          dispatch(setpopupAu(true));
                          dispatch(setMenuOpen(null));
                        }}
                      >
                        <small> Change Audio</small>
                      </div>
                    </>
                  ) : null}

                  <div
                    className="item"
                    onClick={() => {
                      emergencyUnstake("2", char2.id);
                      setselectedOption("2");
                      dispatch(setpopupCh(true));
                      dispatch(setMenuOpen(null));
                    }}
                  >
                    Change Waifu
                  </div>
                  <div
                    className="item"
                    onClick={() => {
                      unstake("2", char2.id);
                    }}
                  >
                    Remove Waifu
                  </div>
                </div>
              )}
            </div>
            <img
              src={`/assets/${char2.id}.png`}
              alt="char"
              className={animateChar === "2" ? "animate-right" : ""}
              onClick={() => {
                animate("2");
                playAudio("2");
              }}
            />
          </div>
        )}
        {/* <video src={ring} autoPlay loop controls={false} /> */}
      </div>
      <div className="ch3">
        {!char3.image ? (
          !waifus ? null : (
            <div className="placeholder">
              <i
                className="fa-solid fa-plus add"
                onClick={() => {
                  setselectedOption("3");
                  dispatch(setpopupCh(true));
                }}
              ></i>
              {/* <video src={ring} autoPlay loop controls={false} /> */}
              <img className="ring" src={ring} alt="ring" />
            </div>
          )
        ) : (
          <div className="char3">
            <div className="header">
              <div className="heart  w-8 h-8">
                <img src={`/hearts/${char3.hearts}.png`} alt="ring" />
              </div>
              <p>{char3.name}</p>
              <i
                className="fa-solid fa-align-justify"
                onClick={() => dispatch(setMenuOpen("ch3"))}
              ></i>
              {menuOpen === "ch3" && (
                <div className="menu">
                  <div
                    className="item"
                    onClick={() => {
                      setselectedOption("3");
                      dispatch(setpopupDes(true));
                      dispatch(setMenuOpen(null));
                    }}
                  >
                    Waifu Description
                  </div>
                  <div
                    className="item"
                    onClick={() => {
                      setselectedOption("3");
                      dispatch(setpopupStats(true));
                      dispatch(setMenuOpen(null));
                    }}
                  >
                    Statistics
                  </div>
                  <div
                    className="item"
                    onMouseOver={() => {
                      setshowExtra(!showExtra);
                    }}
                  >
                    Edit Waifu
                  </div>

                  {showExtra ? (
                    <>
                      {" "}
                      <div
                        className="item ml-2"
                        onClick={() => {
                          setselectedOption("3");
                          dispatch(setpopupDt(true));
                          dispatch(setMenuOpen(null));
                        }}
                      >
                        <small> Edit Description</small>
                      </div>
                      <div
                        className="item ml-2"
                        onClick={() => {
                          setselectedOption("3");
                          dispatch(setpopupAu(true));
                          dispatch(setMenuOpen(null));
                        }}
                      >
                        <small> Change Audio</small>
                      </div>
                    </>
                  ) : null}

                  <div
                    className="item"
                    onClick={() => {
                      emergencyUnstake("3", char3.id);
                      setselectedOption("3");
                      dispatch(setpopupCh(true));
                      dispatch(setMenuOpen(null));
                    }}
                  >
                    Change Waifu
                  </div>
                  <div
                    className="item"
                    onClick={() => {
                      unstake("3", char3.id);
                    }}
                  >
                    Remove Waifu
                  </div>
                </div>
              )}
            </div>
            <img
              src={`/assets/${char3.id}.png`}
              alt="char"
              className={animateChar === "3" ? "animate-left" : ""}
              onClick={() => {
                animate("3");
                playAudio("3");
              }}
            />
          </div>
        )}
      </div>
      <div className="ch4">
        {!char4.image ? (
          !waifus ? null : (
            <div className="placeholder">
              <i
                className="fa-solid fa-plus add"
                onClick={() => {
                  setselectedOption("4");
                  dispatch(setpopupCh(true));
                }}
              ></i>
              {/* <video src={ring} autoPlay loop controls={false} /> */}
              <img className="ring" src={ring} alt="ring" />
            </div>
          )
        ) : (
          <div className="char4">
            <div className="header">
              <div className="heart  w-8 h-8">
                <img src={`/hearts/${char4.hearts}.png`} alt="ring" />
              </div>
              <p>{char4.name}</p>
              <i
                className="fa-solid fa-align-justify"
                onClick={() => dispatch(setMenuOpen("ch4"))}
              ></i>
              {menuOpen === "ch4" && (
                <div className="menu">
                  <div
                    className="item"
                    onClick={() => {
                      setselectedOption("4");
                      dispatch(setpopupDes(true));
                      dispatch(setMenuOpen(null));
                    }}
                  >
                    Waifu Description
                  </div>
                  <div
                    className="item"
                    onClick={() => {
                      setselectedOption("4");
                      dispatch(setpopupStats(true));
                      dispatch(setMenuOpen(null));
                    }}
                  >
                    Statistics
                  </div>
                  <div
                    className="item"
                    onMouseOver={() => {
                      setshowExtra(!showExtra);
                    }}
                  >
                    Edit Waifu
                  </div>

                  {showExtra ? (
                    <>
                      {" "}
                      <div
                        className="item ml-2"
                        onClick={() => {
                          setselectedOption("4");
                          dispatch(setpopupDt(true));
                          dispatch(setMenuOpen(null));
                        }}
                      >
                        <small> Edit Description</small>
                      </div>
                      <div
                        className="item ml-2"
                        onClick={() => {
                          setselectedOption("4");
                          dispatch(setpopupAu(true));
                          dispatch(setMenuOpen(null));
                        }}
                      >
                        <small> Change Audio</small>
                      </div>
                    </>
                  ) : null}

                  <div
                    className="item"
                    onClick={() => {
                      emergencyUnstake("4", char4.id);
                      setselectedOption("4");
                      dispatch(setpopupCh(true));
                      dispatch(setMenuOpen(null));
                    }}
                  >
                    Change Waifu
                  </div>
                  <div
                    className="item"
                    onClick={() => {
                      unstake("4", char4.id);
                    }}
                  >
                    Remove Waifu
                  </div>
                </div>
              )}
            </div>

            <img
              src={`/assets/${char4.id}.png`}
              alt="char"
              className={animateChar === "4" ? "animate-right" : ""}
              onClick={() => {
                animate("4");
                playAudio("4");
              }}
            />
          </div>
        )}
      </div>

      {/* eslint-disable-next-line */}
      {totalStaked == 0 ? (
        <div className="ch5"></div>
      ) : (
        <div className="ch5">
          <button
            onClick={(e) => {
              e.preventDefault();
              updateReward();
            }}
            className="w-1/4 cursor"
            ref={setTriggerRef}
          >
            <img src={`/coins/${calculateCoinBalance(balance)}.png`} alt="" />
          </button>
        </div>
      )}

      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({ className: "tooltip-container" })}
          className="bg-black text-white border p-2 rounded-md "
        >
          <div {...getArrowProps({ className: "tooltip-arrow" })} />
          Click to Update Reward
        </div>
      )}

      {popupCh && (
        <ChPopup
          selectedOption={selectedOption}
          setselectedOption={setselectedOption}
        />
      )}
      {popupDt && <DtPopup selectedOption={selectedOption} />}
      {popupAu && <AuPopup selectedOption={selectedOption} />}
      {popupDes && <DescriptionPopup selectedOption={selectedOption} />}
      {popupStats && <StatsPopup selectedOption={selectedOption} />}
    </div>
  );
};

export default Characters;
