import cat1 from "../../media/audios/cat1.mp3";
import cat2 from "../../media/audios/cat2.mp3";
import cat3 from "../../media/audios/cat3.mp3";
import bakadere1 from "../../media/audios/bakadere1.mp3";
import bakadere2 from "../../media/audios/bakadere2.mp3";
import bakadere3 from "../../media/audios/bakadere3.mp3";
import dandere1 from "../../media/audios/dandere1.mp3";
import dandere2 from "../../media/audios/dandere2.mp3";
import dandere3 from "../../media/audios/dandere3.mp3";
import himedere1 from "../../media/audios/himedere1.mp3";
import himedere2 from "../../media/audios/himedere2.mp3";
import himedere3 from "../../media/audios/himedere3.mp3";
import idol1 from "../../media/audios/idol1.mp3";
import idol2 from "../../media/audios/idol2.mp3";
import idol3 from "../../media/audios/idol3.mp3";
import kuudere1 from "../../media/audios/kuudere1.mp3";
import kuudere2 from "../../media/audios/kuudere2.mp3";
import kuudere3 from "../../media/audios/kuudere3.mp3";
import maid1 from "../../media/audios/maid1.mp3";
import maid2 from "../../media/audios/maid2.mp3";
import maid3 from "../../media/audios/maid3.mp3";
import moekko1 from "../../media/audios/moekko1.mp3";
import moekko2 from "../../media/audios/moekko2.mp3";
import moekko3 from "../../media/audios/moekko3.mp3";
import oneesan1 from "../../media/audios/oneesan1.mp3";
import oneesan2 from "../../media/audios/oneesan2.mp3";
import oneesan3 from "../../media/audios/oneesan3.mp3";
import tsundere1 from "../../media/audios/tsundere1.mp3";
import tsundere2 from "../../media/audios/tsundere2.mp3";
import tsundere3 from "../../media/audios/tsundere3.mp3";
import yandere1 from "../../media/audios/yandere1.mp3";
import yandere2 from "../../media/audios/yandere2.mp3";
import yandere3 from "../../media/audios/yandere3.mp3";

export const audioData = [
  {
    name: "Cat Girl Voice v1",
    audios: [cat1, cat2, cat3],
  },
  {
    name: "Bakadere Voice v1",
    audios: [bakadere1, bakadere2, bakadere3],
  },
  {
    name: "Dandere Voice v1",
    audios: [dandere1, dandere2, dandere3],
  },
  {
    name: "Himedere Voice v1",
    audios: [himedere1, himedere2, himedere3],
  },
  {
    name: "Idol Voice v1",
    audios: [idol1, idol2, idol3],
  },
  {
    name: "Kuudere Voice v1",
    audios: [kuudere1, kuudere2, kuudere3],
  },
  {
    name: "Maid Voice v1",
    audios: [maid1, maid2, maid3],
  },
  {
    name: "moekko",
    audios: [moekko1, moekko2, moekko3],
  },
  {
    name: "Oneesan Voice v1",
    audios: [oneesan1, oneesan2, oneesan3],
  },
  {
    name: "Tsundere Voice v1",
    audios: [tsundere1, tsundere2, tsundere3],
  },
  {
    name: "Yandere Voice v1",
    audios: [yandere1, yandere2, yandere3],
  },
];
