import firebase from "../firebase";
const db = firebase.collection("/waifus");

const getWaifus = async () => {
  const snapshot = await db.get();
  return snapshot.docs.map((doc) => doc.data());
};

const createWaifu = async (waifu) => {
  const snapshot = await db.add(waifu);
  return snapshot.id;
};

const updateWaifu = async (id, waifu) => {
  console.log("id", waifu);
  await db.doc(id).update(waifu);
};

const waifuService = {
  getWaifus,
  createWaifu,
  updateWaifu,
};

export default waifuService;
