import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setpopupMainStats } from "../../redux/actions/styles";
import LineChart from "react-linechart";
import "react-linechart/dist/styles.css";
import { fetchData } from "../../redux/data/dataActions";

const MainStatsPopup = () => {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const { rewardsReleased } = useSelector((state) => state.blockchainData);
  const { balances } = useSelector((state) => state.data);
  const [myStakedTotal, setMyStakedTotal] = useState([0]);
  const [stakedTotal, setStakedTotal] = useState(0);
  const [calTime, setCalculatedTime] = useState(0);
  const [stakePower, setStakePower] = useState(0);
  const [earned, setEarned] = useState(0);

  const getTotalSupply = () => {
    setStakePower((rewardsReleased / 7 / 100000000000000).toFixed(1));
  };

  const myStakeTotal = () => {
    blockchain.stakingContract.methods
      .getStakedTokens(blockchain.account)
      .call((err, res) => {
        if (err) {
          console.log(err);
        } else {
          setMyStakedTotal(res);
        }
      });
  };

  const totalStaked = () => {
    blockchain.stakingContract.methods.stakedTotal().call((err, res) => {
      if (err) {
        console.log(err);
      } else {
        setStakedTotal(res);
      }
    });
  };

  const getEarned = () => {
    blockchain.stakingContract.methods
      .stakers(blockchain.account)
      .call((err, res) => {
        if (err) {
          console.log(err);
        } else {
          setEarned((res.rewardsReleased / 100000000000000).toFixed(2));
        }
      });
  };
  const timeStaked = () => {
    if (balances[0].balance > 0) {
      calculatedTime(balances[0].date);
    } else {
      return null;
    }
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.stakingContract !== null) {
      dispatch(fetchData(blockchain.account));
      myStakeTotal();
      totalStaked();
      timeStaked();
      getEarned();
      getTotalSupply();
    }
  };

  const calculatedTime = (time) => {
    const startDate = new Date(time);

    //format Days Hrs minutes seconds
    const timeDiff = Math.abs(Date.now() - startDate);
    const diffDays = Math.floor(timeDiff / (1000 * 3600 * 24));
    const diffHrs = Math.floor((timeDiff % (1000 * 3600 * 24)) / (1000 * 3600));
    const diffMins = Math.floor((timeDiff % (1000 * 3600)) / (1000 * 60));
    const diffSecs = Math.floor((timeDiff % (1000 * 60)) / 1000);
    let formattedTime =
      diffDays + " D " + diffHrs + " H " + diffMins + " m " + diffSecs + " s";
    setCalculatedTime(formattedTime);
  };

  const data = [
    {
      color: "steelblue",
      points: [],
    },
  ];

  const month = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  balances.slice(-7).map((item) => {
    return data[0].points.push({
      x: new Date(item.date).getDate(),
      y: item.balance / 100000000000000,
    });
  });

  useEffect(() => {
    getData();
    console.log(data);

    //eslint-disable-next-line
  }, []);

  return (
    <div className="ms-popup">
      <div
        className="bg-dark"
        onClick={() => dispatch(setpopupMainStats(false))}
      />

      <div className="container bg-red-600 text-black ">
        <div className=" font-bold  ">
          <h1 className="text-center">Waifu Statistics</h1>

          <div className="flex justify-around">
            <div className="mt-8">
              <p className="text-xl tracking-wider font-light">
                <strong>Total Waifu(s):</strong> {stakedTotal}
              </p>
              <p className="text-xl tracking-wider font-light">
                <strong>Waifu(s) Staked:</strong> {myStakedTotal.length}
              </p>
              <p className="text-xl tracking-wider font-light">
                <strong>Total 2DC Earned:</strong> <small>{earned}</small>
              </p>
              <p className="text-xl tracking-wider font-light">
                <strong>Total Time Staked:</strong> <small>{calTime}</small>
              </p>
              <p className="text-xl tracking-wider font-light">
                <strong>Current Staking Power:</strong>{" "}
                <small>{stakePower}</small>
                <small class="font-bold text-xs"> 2DC/Day</small>
              </p>
            </div>
            <div id="chart" className="w-100">
              <LineChart
                width={400}
                height={300}
                data={data}
                xLabel={`${
                  month[new Date().getMonth()]
                } ${new Date().getFullYear()}`}
                yLabel="2DC"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainStatsPopup;
