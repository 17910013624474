import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setpopupStats } from "../../redux/actions/styles";

const StatsPopup = (props) => {
  const { selectedOption } = props;
  const dispatch = useDispatch();

  const { currentRoom, roomData } = useSelector((state) => state.data);

  const [name] = useState(
    selectedOption ? roomData[currentRoom][`char${selectedOption}`].name : ""
  );

  const [stakeTime] = useState(
    selectedOption
      ? roomData[currentRoom][`char${selectedOption}`].stakeTime
      : ""
  );

  const [rarity] = useState(
    selectedOption ? roomData[currentRoom][`char${selectedOption}`].rarity : ""
  );

  const calculatedTime = (time) => {
    const startDate = new Date(time);
    console.log(startDate);
    //format Days Hrs minutes seconds
    const timeDiff = Math.abs(Date.now() - startDate);
    const diffDays = Math.floor(timeDiff / (1000 * 3600 * 24));
    const diffHrs = Math.floor((timeDiff % (1000 * 3600 * 24)) / (1000 * 3600));
    const diffMins = Math.floor((timeDiff % (1000 * 3600)) / (1000 * 60));
    const diffSecs = Math.floor((timeDiff % (1000 * 60)) / 1000);
    let formattedTime =
      diffDays + " D " + diffHrs + " H " + diffMins + " m " + diffSecs + " s";
    return formattedTime;
  };
  const calculate2DC = (time, r) => {
    //calculate time difference between now and start date in minutes
    let date = new Date();
    let now = date.getTime();
    const diffMiliSeconds = now - time;
    const diffSeconds = diffMiliSeconds / 1000;
    const diffMinutes = diffSeconds / 60;

    if ("C" === r || "Co" === r.slice(0, 2)) {
      return diffMinutes * 1 * 0.01;
    } else if ("UC" === r || "Unc" === r.slice(0, 3)) {
      return (((diffMinutes * 3) / 2) * 0.01).toFixed(2);
    } else if ("R" === r || "Rare" === r.slice(0, 4)) {
      return (((diffMinutes * 27) / 10) * 0.01).toFixed(2);
    } else if ("SR" === r) {
      return (((diffMinutes * 11) / 2) * 0.01).toFixed(2);
    } else if ("SSR" === r) {
      return (diffMinutes * 15 * 0.01).toFixed(2);
    } else if ("UR" === r) {
      return (diffMinutes * 45 * 0.01).toFixed(2);
    }
  };

  return (
    <div className="dt-popup">
      <div className="bg-dark" onClick={() => dispatch(setpopupStats(false))} />

      <div className="container">
        <p className="title mb-3"> {name} Statistics</p>
        <p>
          Total 2DC Generated:{" "}
          <strong>{calculate2DC(stakeTime, rarity)}</strong>
        </p>

        <p>
          Total Time Staked: <strong> {calculatedTime(stakeTime)}</strong>
        </p>
      </div>
    </div>
  );
};

export default StatsPopup;
