import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import scene1 from "../../media/images/bedroom royal evening.png";
import scene2 from "../../media/images/classroom sunset.png";
import scene3 from "../../media/images/onsen nature morning.png";
import video1 from "../../media/images/onsen luxury morning.mp4";
import { setbackground } from "../../redux/actions/data";
import { setpopupBg } from "../../redux/actions/styles";
import axios from "axios";

const data = [
  {
    id: "1",
    name: "Bedroom Royal Evening",
    type: "img",
    img: scene1,
  },
  {
    id: "2",
    name: "Classroom Sunset",
    type: "img",
    img: scene2,
  },
  {
    id: "3",
    name: "Onsen Nature Morning",
    type: "img",
    img: scene3,
  },
  {
    id: "4",
    name: "Onsen Luxury Morning",
    type: "video",
    img: video1,
  },
];

const BgPopup = () => {
  const dispatch = useDispatch();
  const cardScrollRef = useRef();
  const blockchain = useSelector((state) => state.blockchain);
  const [filteredData, setFilteredData] = useState([]);
  const { currentRoom } = useSelector((state) => state.data);
  let availableBgs = [];

  const scrollTable = (direction) => {
    if (direction === "left") {
      cardScrollRef.current.scrollLeft -= 300;
    }
    if (direction === "right") {
      cardScrollRef.current.scrollLeft += 300;
    }
  };

  // Replace with your Alchemy API key:
  const apiKey = "-om1CTAyfT7HUDF1B-lpwXSV0iMyNdug";
  //change For Mainnet
  const baseURL = `https://polygon-mainnet.g.alchemy.com/v2/${apiKey}/getNFTs/`;
  const contractAddr = "0x2953399124F0cBB46d2CbACD8A89cF0599974963";

  let config = {
    method: "get",
    url: `${baseURL}?owner=${blockchain.account}&contractAddresses[]=${contractAddr}`,
  };

  const getNFTs = () => {
    // Make the request and print the formatted response:
    axios(config)
      .then((nfts) => {
        for (const nft of nfts.data.ownedNfts) {
          data.forEach((item) => {
            if (item.name === nft.title) {
              availableBgs.push(nft.title);
            }
          });

          console.log(availableBgs);
          let filteredData = data.filter(
            (bg) => availableBgs.indexOf(bg.name) > -1
          );
          setFilteredData(filteredData);
        }
      })
      .catch((error) => console.log(error));
  };

  const getData = () => {
    if (blockchain.account !== "") {
      getNFTs();
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="change-bg-popup ">
      <div className="bg-dark" onClick={() => dispatch(setpopupBg(false))} />
      <div className="container">
        <p className="title">Select your background</p>
        <i
          className="fa-solid fa-angle-left arrow-left"
          onClick={() => scrollTable("left")}
        ></i>
        <i
          className="fa-solid fa-angle-right arrow-right"
          onClick={() => scrollTable("right")}
        ></i>
        <div className="fade-left" />
        <div className="fade-right" />
        <div className="images" ref={cardScrollRef}>
          {filteredData.map((item) => (
            <div
              key={item.id}
              className="img"
              onClick={() => {
                const bg = item.img ? item.img : item.video;
                dispatch(setbackground(currentRoom, bg));
                dispatch(setpopupBg(false));
              }}
            >
              {item.img ? (
                <img src={item.img} alt="background" />
              ) : (
                <video src={item.video} muted autoPlay loop />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BgPopup;
