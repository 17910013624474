import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setcharacter } from "../../redux/actions/data";
import { setpopupCh } from "../../redux/actions/styles";
import axios from "axios";
import { fetchData } from "../../redux/data/dataActions";

const ChPopup = (props) => {
  const { selectedOption } = props;
  const dispatch = useDispatch();
  const cardScrollRef = useRef();

  const { currentRoom } = useSelector((state) => state.data);

  const blockchain = useSelector((state) => state.blockchain);

  const [ownedTokens, setOwnedTokens] = useState([]);

  let date = new Date();
  let dateString = date.getTime();

  const scrollTable = (direction) => {
    if (direction === "left") {
      cardScrollRef.current.scrollLeft -= 300;
    }
    if (direction === "right") {
      cardScrollRef.current.scrollLeft += 300;
    }
  };
  // Replace with your Alchemy API key:
  const apiKey = "wh1yOF96xcS02w4Xs1_rnmhOINNTPImM";
  //change For Mainnet
  const baseURL = `https://eth-mainnet.g.alchemy.com/v2/${apiKey}/getNFTs/`;
  const contractAddr = "0xcC63542B0fc2131A6b0Ca593362B5224F7A9e024";
  const contractAddrStaking = "0xb1852c83574ee7745fe10a0538c8acb30d4fe9fc";

  let config = {
    method: "get",
    url: `${baseURL}?owner=${blockchain.account}&contractAddresses[]=${contractAddr}`,
  };

  const getNFTs = () => {
    let tokens = [];
    // Make the request and print the formatted response:
    axios(config)
      .then((nfts) => {
        for (const nft of nfts.data.ownedNfts) {
          console.log(nft);
          //remove nft with 0
          if (
            // eslint-disable-next-line
            nft.id.tokenId !=
              0x0000000000000000000000000000000000000000000000000000000000000000 &&
            nft.metadata.name !== "Waifu Hidden"
          ) {
            tokens.push({
              image: nft.media[0].gateway,
              id: parseInt(nft.id.tokenId, 16),
              name: nft.metadata.name,
              hearts: nft.metadata.attributes[0].value.split(/(?=\d+)/)[0],
              rarity: nft.metadata.attributes[0].value.split(/(?=\d+)/)[0],
            });
          }
        }

        setOwnedTokens(tokens);
        console.log(tokens);
      })
      .catch((error) => console.log(error));
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.stakingContract !== null) {
      dispatch(fetchData(blockchain.account));
      getNFTs();
    }
  };

  const stake = (item) => {
    console.log(item);
    blockchain.smartContract.methods
      .isApprovedForAll(blockchain.account, contractAddrStaking)
      .call()
      .then((isApproved) => {
        console.log("IsApproved", isApproved);
        console.log("Item", item);
        if (isApproved) {
          blockchain.stakingContract.methods
            .stake(item.id, item.rarity, dateString)
            .send({
              gasLimit: String(500000),
              from: blockchain.account,
            })
            .then(() => {
              dispatch(setcharacter(currentRoom, selectedOption, item));
              dispatch(setpopupCh(false));
              getData();
            })
            .catch((err) => console.log(err));
        } else {
          blockchain.smartContract.methods
            .setApprovalForAll(contractAddrStaking, true)
            .send({
              gasLimit: String(250000),
              from: blockchain.account,
            })

            .then(() => {
              if (ownedTokens.length > 0) {
                blockchain.stakingContract.methods
                  .stake(item.id, item.rarity, dateString)
                  .send({
                    gasLimit: String(300000),
                    from: blockchain.account,
                  })
                  .then(() => {
                    dispatch(setcharacter(currentRoom, selectedOption, item));
                    dispatch(setpopupCh(false));
                    getData();
                  })
                  .catch((err) => console.log(err));
              } else {
                console.log("First Mint NFTs");
              }
            });
        }
      });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="change-ch-popup">
      <div className="bg-dark" onClick={() => dispatch(setpopupCh(false))} />

      <div className="container">
        <p className="title">Select your character</p>

        <i
          className="fa-solid fa-angle-left arrow-left"
          onClick={() => scrollTable("left")}
        ></i>
        <i
          className="fa-solid fa-angle-right arrow-right"
          onClick={() => scrollTable("right")}
        ></i>
        <div className="fade-left" />
        <div className="fade-right" />
        <div className="images" ref={cardScrollRef}>
          {ownedTokens.map((item) => (
            <div
              key={item.id}
              className="img"
              onClick={() => {
                stake(item);
              }}
            >
              <img src={item.image} alt={item.name} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChPopup;
