import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ButtonPrimary from "../../../../components/button/ButtonPrimary";
import { setaddRoom, setcurrentRoom,  setwaifus } from "../../../../redux/actions/data";
import { setpopupBg } from "../../../../redux/actions/styles";

const RoomAdd = () => {
  const dispatch = useDispatch();
  const { rooms, currentRoom, addRoomCount, waifus } = useSelector((state) => state.data);
  const [roomName, setroomName] = useState(`Room ${rooms.length + 1}`);
  const [addInputVisible, setaddInputVisible] = useState(false);
  const [menuOpen, setmenuOpen] = useState(null);
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current && inputRef.current.focus();
  }, []);

  useEffect(() => {
    setroomName(`Room ${addRoomCount + 1}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rooms]);

  return (
    <div className="room-add">
      {menuOpen && <div className="bg-menu" onClick={() => setmenuOpen(null)} />}

      <div className="content">
       
        {rooms.map((item) => (
          <ButtonPrimary
            key={item.id}
            text={item.name}
            className={currentRoom === item.id ? "btn-active" : ""}
            onClick={() => dispatch(setcurrentRoom(item.id))}
            // iconRight="fa-solid fa-xmark"
            // onClickIconRight={() => dispatch(setdeleteRoom(item.id))}
          />
        ))}

        {rooms.length < 5 && <i className="fa-solid fa-plus add" onClick={() => dispatch(setaddRoom(roomName))}></i>}
        <div className="actions">
          <div className="menu-wrapper">
            <ButtonPrimary
              text="Room Settings"
              onClick={() => {
                if (menuOpen === "settings") {
                  setmenuOpen(null);
                } else {
                  setmenuOpen("settings");
                }
              }}
            />
            {menuOpen === "settings" && (
              <div className="menu">
                <div
                  className="menu-item"
                  onClick={() => {
                    dispatch(setpopupBg(true));
                    setmenuOpen(null);
                  }}
                >
                  <i className="fa-solid fa-image"></i>
                  <p>Change Background</p>
                </div>
                {!waifus && (
                  <div
                    className="menu-item"
                    onClick={() => {
                      dispatch(setwaifus(true));
                      setmenuOpen(null);
                    }}
                  >
                    <i className="fa-solid fa-pen-to-square"></i>
                    <p>Edit Waifus</p>
                  </div>
                )}
              </div>
            )}
          </div>
          {waifus && (
            <ButtonPrimary
              text="Confirm"
              onClick={() => {
                dispatch(setwaifus(false));
              }}
            />
          )}
        </div>
      </div>
      {addInputVisible && (
        <div className="add-input">
          <input
            placeholder="Room Name"
            ref={inputRef}
            value={roomName}
            onChange={(e) => setroomName(e.target.value)}
          />
          <i
            className="fa-solid fa-check check"
            onClick={() => {
              dispatch(setaddRoom(roomName));
              setaddInputVisible(false);
            }}
          ></i>
          <i
            className="fa-solid fa-xmark cross"
            onClick={() => {
              setroomName(`Room ${rooms.length + 1}`);
              setaddInputVisible(false);
            }}
          ></i>
        </div>
      )}
    </div>
  );
};

export default RoomAdd;
