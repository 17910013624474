import {
  POPUP_AU,
  POPUP_BG,
  POPUP_CH,
  POPUP_DT,
  POPUP_ROOM,
  POPUP_DES,
  POPUP_STATS,
  POPUP_MAIN_STATS,
} from "../types/styles";

export const setpopupRoom = (isPopup) => async (dispatch) => {
  dispatch({ type: POPUP_ROOM, payload: isPopup });
};

export const setpopupBg = (isPopup) => async (dispatch) => {
  dispatch({ type: POPUP_BG, payload: isPopup });
};

export const setpopupCh = (isPopup) => async (dispatch) => {
  dispatch({ type: POPUP_CH, payload: isPopup });
};
export const setpopupDt = (isPopup) => async (dispatch) => {
  dispatch({ type: POPUP_DT, payload: isPopup });
};
export const setpopupAu = (isPopup) => async (dispatch) => {
  dispatch({ type: POPUP_AU, payload: isPopup });
};

export const setpopupStats = (isPopup) => async (dispatch) => {
  dispatch({ type: POPUP_STATS, payload: isPopup });
};

export const setpopupDes = (isPopup) => async (dispatch) => {
  dispatch({ type: POPUP_DES, payload: isPopup });
};

export const setpopupMainStats = (isPopup) => async (dispatch) => {
  dispatch({ type: POPUP_MAIN_STATS, payload: isPopup });
};
