import firebase from "firebase/app";
import "firebase/firestore";
let config = {
  apiKey: "AIzaSyBJZHx7l3wShyQy7Wn6qXLiTIYajWMAMMU",
  authDomain: "waifuhc.firebaseapp.com",
  projectId: "waifuhc",
  storageBucket: "waifuhc.appspot.com",
  messagingSenderId: "323951851974",
  appId: "1:323951851974:web:b0d6ca3f50c269c6addac7",
  measurementId: "G-3SWGKJTE1K",
};
firebase.initializeApp(config);
export default firebase.firestore();
