import {
  ADD_ROOM,
  ADD_BALANCE,
  BACKGROUND,
  CHARACTER,
  CURRENT_AUDIO,
  CURRENT_ROOM,
  DELETE_ROOM,
  DETAILS,
  REMOVE_CHARACTER,
  ROOMS,
  WAIFUS,
  SET_STATE,
  MENU_OPEN,
} from "../types/data";

export const setwaifus = (isWaifu) => (dispatch) => {
  dispatch({ type: WAIFUS, payload: isWaifu });
};

export const setState = (state) => (dispatch) => {
  dispatch({ type: SET_STATE, payload: state });
};

export const setrooms = (room) => (dispatch) => {
  dispatch({ type: ROOMS, payload: room });
};

export const setcurrentRoom = (room) => (dispatch) => {
  dispatch({ type: CURRENT_ROOM, payload: room });
};

export const setaddRoom = (roomName) => (dispatch) => {
  dispatch({ type: ADD_ROOM, payload: roomName });
};

export const setaddBalance = (bal, date) => (dispatch) => {
  dispatch({ type: ADD_BALANCE, payload: { bal: bal, date: date } });
};

export const setdeleteRoom = (roomId) => (dispatch) => {
  dispatch({ type: DELETE_ROOM, payload: roomId });
};

export const setbackground = (currentRoom, background) => (dispatch) => {
  dispatch({ type: BACKGROUND, payload: { currentRoom, background } });
};

export const setcharacter = (room, option, character) => (dispatch) => {
  dispatch({ type: CHARACTER, payload: { room, option, character } });
};

export const removecharacter = (roomRm, optionRm) => (dispatch) => {
  dispatch({
    type: REMOVE_CHARACTER,
    payload: { roomRm, optionRm },
  });
};

export const setdetails = (roomDt, optionDt, nameDt, descDt) => (dispatch) => {
  dispatch({ type: DETAILS, payload: { roomDt, optionDt, nameDt, descDt } });
};

export const setaudio = (roomAu, optionAu, audioAu) => (dispatch) => {
  dispatch({ type: CURRENT_AUDIO, payload: { roomAu, optionAu, audioAu } });
};

export const setMenuOpen = (boolean) => (dispatch) => {
  dispatch({ type: MENU_OPEN, payload: boolean });
};
