import { combineReducers } from "redux";
import authReducer from "./auth";
import dataReducer from "./data";
import stylesReducer from "./styles";
import blockchainReducer from "../blockchain/blockchainReducer";
import blockchainDataReducer from "../data/dataReducer";



const rootReducer = combineReducers({
  styles: stylesReducer,
  data: dataReducer,
  auth: authReducer,
  blockchain: blockchainReducer,
  blockchainData: blockchainDataReducer
});

export default rootReducer;
