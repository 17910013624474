import { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setaudio } from "../../redux/actions/data";
import { setpopupAu } from "../../redux/actions/styles";
import { audioData } from "../../redux/reducers/audioData";
import axios from "axios";

const AuPopup = (props) => {
  const { selectedOption } = props;
  const dispatch = useDispatch();
  const cardScrollRef = useRef();
  const { currentRoom } = useSelector((state) => state.data);
  const blockchain = useSelector((state) => state.blockchain);
  const [filteredData, setFilteredData] = useState([]);
  let availableAudios = [];

  const scrollTable = (direction) => {
    if (direction === "left") {
      cardScrollRef.current.scrollLeft -= 300;
    }
    if (direction === "right") {
      cardScrollRef.current.scrollLeft += 300;
    }
  };

  // Replace with your Alchemy API key:
  const apiKey = "-om1CTAyfT7HUDF1B-lpwXSV0iMyNdug";
  //change For Mainnet
  const baseURL = `https://polygon-mainnet.g.alchemy.com/v2/${apiKey}/getNFTs/`;
  const contractAddr = "0x2953399124F0cBB46d2CbACD8A89cF0599974963";

  let config = {
    method: "get",
    url: `${baseURL}?owner=${blockchain.account}&contractAddresses[]=${contractAddr}`,
  };

  const getNFTs = () => {
    // Make the request and print the formatted response:
    console.log("Characters Audio");
    axios(config)
      .then((nfts) => {
        for (const nft of nfts.data.ownedNfts) {
          audioData.forEach((item) => {
            if (item.name === nft.title) {
              availableAudios.push(nft.title);
            }
          });
          console.log(availableAudios);
          let data = audioData.filter(
            (audio) => availableAudios.indexOf(audio.name) > -1
          );
          setFilteredData(data);
        }
      })
      .catch((error) => console.log(error));
  };

  const getData = () => {
    if (blockchain.account !== "") {
      getNFTs();
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="au-popup">
      <div className="bg-dark" onClick={() => dispatch(setpopupAu(false))} />
      <div className="container">
        <p className="title">Select your audio</p>
        <i
          className="fa-solid fa-angle-left arrow-left"
          onClick={() => scrollTable("left")}
        ></i>
        <i
          className="fa-solid fa-angle-right arrow-right"
          onClick={() => scrollTable("right")}
        ></i>
        <div className="fade-left" />
        <div className="fade-right" />
        <div className="audios" ref={cardScrollRef}>
          {filteredData &&
            filteredData.map((item) => (
              <div
                key={item.name}
                className="audio"
                onClick={() => {
                  dispatch(setaudio(currentRoom, selectedOption, item));
                  dispatch(setpopupAu(false));
                }}
              >
                <i className="fa-solid fa-music"></i>
                <p>{item.name}</p>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default AuPopup;
