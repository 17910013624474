export const ROOMS = "ROOMS";
export const CURRENT_ROOM = "CURRENT_ROOM";
export const ADD_ROOM = "ADD_ROOM";
export const ADD_BALANCE = "ADD_BALANCE";
export const BACKGROUND = "BACKGROUND";
export const DELETE_ROOM = "DELETE_ROOM";
export const CHARACTER = "CHARACTER";
export const REMOVE_CHARACTER = "REMOVE_CHARACTER";
export const WAIFUS = "WAIFUS";
export const DETAILS = "DETAILS";
export const CURRENT_AUDIO = "CURRENT_AUDIO";
export const GET_CHARACTERS = "GET_CHARACTERS";
export const SET_STATE = "SET_STATE";
export const MENU_OPEN = "MENU_OPEN";
