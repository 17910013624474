const initialState = {
  loading: false,
  totalSupply: 0,
  stakedTotal: 0,
  totalStaked: 0,
  balance: 0,
  rewardsReleased: 0,
  rarities: [],
  stakeTimes: [],
  cost: 0,
  error: false,
  errorMsg: "",
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        totalSupply: action.payload.totalSupply,
        stakedTotal: action.payload.stakedTotal,
        totalStaked: action.payload.totalStaked,
        balance: action.payload.balance,
        rewardsReleased: action.payload.rewardsReleased,
        rarities: action.payload.rarities,
        stakeTimes: action.payload.stakeTimes,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
