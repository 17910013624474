import {
  ADD_ROOM,
  ADD_BALANCE,
  BACKGROUND,
  CHARACTER,
  CURRENT_AUDIO,
  CURRENT_ROOM,
  DELETE_ROOM,
  DETAILS,
  REMOVE_CHARACTER,
  ROOMS,
  WAIFUS,
  SET_STATE,
  MENU_OPEN,
} from "../types/data";
import waifuService from "../../service/waifu.service";

let date = new Date();
let dateString = date.getTime();

const updateWaifu = (id, waifu) => {
  console.log(waifu);
  waifuService
    .updateWaifu(id, waifu)
    .then((data) => {
      return console.log(data);
    })
    .catch((err) => {
      return console.log(err);
    });
};

let initialState = {
  waifus: false,
  menOpen: null,
  currentRoom: "1",
  rooms: [{ id: "1", name: "Room 1" }],
  balances: [{ balance: 0, date: dateString }],
  roomData: {
    1: {
      background: "",
      char1: {
        id: 0,
        image: null,
        name: "",
        rarity: "",
        hearts: "",
        desc: "",
        audio: null,
        stakeTime: null,
      },
      char2: {
        id: 0,
        image: null,
        name: "",
        rarity: "",
        hearts: "",
        desc: "",
        audio: null,
        stakeTime: null,
      },
      char3: {
        id: 0,
        image: null,
        name: "",
        rarity: "",
        hearts: "",
        desc: "",
        audio: null,
        stakeTime: null,
      },
      char4: {
        id: 0,
        image: null,
        name: "",
        rarity: "",
        hearts: "",
        desc: "",
        audio: null,
        stakeTime: null,
      },
    },
  },
  addRoomCount: 1,
  account: "",
  id: "",
};

const dataReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case WAIFUS:
      updateWaifu(state.id, { waifus: payload });
      return { ...state, waifus: payload };

    case SET_STATE:
      return { ...state, ...payload };
    case ROOMS:
      updateWaifu(state.id, { rooms: payload });
      return { ...state, rooms: payload };
    case CURRENT_ROOM:
      updateWaifu(state.id, { currentRoom: payload });
      return { ...state, currentRoom: payload };
    case ADD_ROOM:
      updateWaifu(state.id, {
        rooms: [...state.rooms, { id: state.addRoomCount + 1, name: payload }],
        roomData: {
          ...state.roomData,
          [state.addRoomCount + 1]: {
            background: null,
            char1: {
              id: 0,
              image: null,
              name: "",
              rarity: "",
              hearts: "",
              desc: "",
              audio: null,
              stakeTime: null,
            },
            char2: {
              id: 0,
              image: null,
              name: "",
              rarity: "",
              hearts: "",
              desc: "",
              audio: null,
              stakeTime: null,
            },
            char3: {
              id: 0,
              image: null,
              name: "",
              rarity: "",
              hearts: "",
              desc: "",
              audio: null,
              stakeTime: null,
            },
            char4: {
              id: 0,
              image: null,
              name: "",
              rarity: "",
              hearts: "",
              desc: "",
              audio: null,
              stakeTime: null,
            },
          },
        },
        addRoomCount: state.addRoomCount + 1,
        currentRoom: state.addRoomCount + 1,
      });
      return {
        ...state,
        rooms: [...state.rooms, { id: state.addRoomCount + 1, name: payload }],
        roomData: {
          ...state.roomData,
          [state.addRoomCount + 1]: {
            background: null,
            char1: {
              id: 0,
              image: null,
              name: "",
              rarity: "",
              hearts: "",
              desc: "",
              audio: null,
              stakeTime: null,
            },
            char2: {
              id: 0,
              image: null,
              name: "",
              rarity: "",
              hearts: "",
              desc: "",
              audio: null,
              stakeTime: null,
            },
            char3: {
              id: 0,
              image: null,
              name: "",
              rarity: "",
              hearts: "",
              desc: "",
              audio: null,
              stakeTime: null,
            },
            char4: {
              id: 0,
              image: null,
              name: "",
              rarity: "",
              hearts: "",
              desc: "",
              audio: null,
              stakeTime: null,
            },
          },
        },
        addRoomCount: state.addRoomCount + 1,
        currentRoom: state.addRoomCount + 1,
      };

    case ADD_BALANCE:
      const { bal, date } = payload;
      let balance = state.balances[state.balances.length - 1].balance + bal;
      if (
        state.balances[state.balances.length - 1].balance < balance &&
        new Date(
          state.balances[state.balances.length - 1].date
        ).toLocaleDateString() === new Date(date).toLocaleDateString()
      ) {
        console.log("same day");
        let array = state.balances;
        array.pop();

        updateWaifu(state.id, {
          balances: [...array, { balance: balance, date: date }],
        });
      } else if (
        new Date(
          state.balances[state.balances.length - 1].date
        ).toLocaleDateString() !== new Date(date).toLocaleDateString()
      ) {
        console.log("new day");
        updateWaifu(state.id, {
          balances: [...state.balances, { balance: balance, date: date }],
        });
      }

      return {
        ...state,
        balances: [...state.balances, { balance: balance, date: date }],
      };

    case DELETE_ROOM:
      let newRooms = JSON.parse(JSON.stringify(state.rooms));
      let index = state.rooms.findIndex(function (o) {
        return o.id === payload;
      });
      if (index !== -1) newRooms.splice(index, 1);

      const newRooomData = JSON.parse(JSON.stringify(state.roomData));

      delete newRooomData[payload];
      updateWaifu(state.id, {
        currentRoom: state.rooms[index - 1].id,
        rooms: newRooms,
        roomData: newRooomData,
      });
      return {
        ...state,
        currentRoom: state.rooms[index - 1].id,
        rooms: newRooms,
        roomData: newRooomData,
      };

    case BACKGROUND:
      const { currentRoom, background } = payload;
      updateWaifu(state.id, {
        roomData: {
          ...state.roomData,
          [currentRoom]: {
            ...state.roomData[currentRoom],
            background: background,
          },
        },
      });
      return {
        ...state,
        roomData: {
          ...state.roomData,
          [currentRoom]: {
            ...state.roomData[currentRoom],
            background: background,
          },
        },
      };

    case CHARACTER:
      const { room, option, character } = payload;
      updateWaifu(state.id, {
        roomData: {
          ...state.roomData,
          [room]: {
            ...state.roomData[room],
            [`char${option}`]: {
              ...state.roomData[room][`char${option}`],
              image: character.image,
              name: character.name,
              id: character.id,
              rarity: character.rarity,
              hearts: character.hearts,
              stakeTime: dateString,
            },
          },
        },
      });

      return {
        ...state,
        roomData: {
          ...state.roomData,
          [room]: {
            ...state.roomData[room],
            [`char${option}`]: {
              ...state.roomData[room][`char${option}`],
              image: character.image,
              name: character.name,
              id: character.id,
              rarity: character.rarity,
              hearts: character.hearts,
              stakeTime: dateString,
            },
          },
        },
      };

    case DETAILS:
      const { roomDt, optionDt, nameDt, descDt } = payload;
      updateWaifu(state.id, {
        roomData: {
          ...state.roomData,
          [roomDt]: {
            ...state.roomData[roomDt],
            [`char${optionDt}`]: {
              ...state.roomData[roomDt][`char${optionDt}`],
              name: nameDt,
              desc: descDt,
            },
          },
        },
      });

      return {
        ...state,
        roomData: {
          ...state.roomData,
          [roomDt]: {
            ...state.roomData[roomDt],
            [`char${optionDt}`]: {
              ...state.roomData[roomDt][`char${optionDt}`],
              name: nameDt,
              desc: descDt,
            },
          },
        },
      };

    case CURRENT_AUDIO:
      const { roomAu, optionAu, audioAu } = payload;
      updateWaifu(state.id, {
        roomData: {
          ...state.roomData,
          [roomAu]: {
            ...state.roomData[roomAu],
            [`char${optionAu}`]: {
              ...state.roomData[roomAu][`char${optionAu}`],
              audio: audioAu,
            },
          },
        },
      });

      return {
        ...state,
        roomData: {
          ...state.roomData,
          [roomAu]: {
            ...state.roomData[roomAu],
            [`char${optionAu}`]: {
              ...state.roomData[roomAu][`char${optionAu}`],
              audio: audioAu,
            },
          },
        },
      };

    case REMOVE_CHARACTER:
      const { roomRm, optionRm } = payload;

      updateWaifu(state.id, {
        ...state.roomData,
        roomData: {
          [roomRm]: {
            ...state.roomData[roomRm],
            [`char${optionRm}`]: {
              ...state.roomData[roomRm][`char${optionRm}`],
              id: 0,
              image: null,
              name: "",
              rarity: "",
              hearts: "",
              desc: "",
              audio: null,
              stakeTime: null,
            },
          },
        },
      });

      return {
        ...state,
        roomData: {
          ...state.roomData,
          [roomRm]: {
            ...state.roomData[roomRm],
            [`char${optionRm}`]: {
              ...state.roomData[roomRm][`char${optionRm}`],
              id: 0,
              image: null,
              name: "",
              rarity: "",
              hearts: "",
              desc: "",
              audio: null,
              stakeTime: null,
            },
          },
        },
      };

    case MENU_OPEN:
      return {
        ...state,
        menuOpen: payload,
      };

    default:
      return state;
  }
};

export default dataReducer;
