import {
  POPUP_AU,
  POPUP_BG,
  POPUP_CH,
  POPUP_DT,
  POPUP_ROOM,
  POPUP_STATS,
  POPUP_DES,
  POPUP_MAIN_STATS,
} from "../types/styles";

const initialState = {
  popupRoom: true,
  popupBg: false,
  popupCh: false,
  popupDt: false,
  popupAu: false,
  popupDes: false,
  popupStats: false,
  popupMainStats: false,
};

const stylesReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case POPUP_ROOM:
      return { ...state, popupRoom: payload };
    case POPUP_BG:
      return { ...state, popupBg: payload };
    case POPUP_CH:
      return { ...state, popupCh: payload };
    case POPUP_DT:
      return { ...state, popupDt: payload };
    case POPUP_AU:
      return { ...state, popupAu: payload };
    case POPUP_DES:
      return { ...state, popupDes: payload };
    case POPUP_STATS:
      return { ...state, popupStats: payload };
    case POPUP_MAIN_STATS:
      return { ...state, popupMainStats: payload };
    default:
      return state;
  }
};

export default stylesReducer;
