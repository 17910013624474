import React from "react";
import { useDispatch } from "react-redux";
import ButtonPrimary from "../../../../components/button/ButtonPrimary";
// import { setlogIn } from "../../../../redux/actions/auth";
import { connect } from "../../../../redux/blockchain/blockchainActions";

const ConnectWallet = () => {
  const dispatch = useDispatch();
  return (
    <div className="connect-wallet">

      <ButtonPrimary text="Connect Wallet" onClick={(e) => {  e.preventDefault();
        dispatch(connect())}} />
    </div>
  );
};

export default ConnectWallet;
