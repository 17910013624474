import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import logo from "../../media/images/logo.png";
import mmLogo from "../../media/images/mm-logo.png";
import BgPopup from "../../components/BgPopup";
import MainStatsPopup from "../../components/MainStatsPopup";
import { connect } from "../../redux/blockchain/blockchainActions";
import { setpopupMainStats } from "../../redux/actions/styles";

const Navbar = () => {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);

  const [menuOpen, setmenuOpen] = useState(null);

  const { popupBg, popupMainStats } = useSelector((state) => state.styles);

  const { isAuthenticated } = useSelector((state) => state.auth);

  if (popupMainStats) {
    //change style of class .characters .header z-index to 0
    document.querySelector(".characters").style.zIndex = "0";
  }

  useEffect(() => {
    setmenuOpen(null);
    //eslint-disable-next-line
  }, [blockchain.account]);

  return (
    <div className="navbar">
      {menuOpen && (
        <div className="bg-menu" onClick={() => setmenuOpen(null)} />
      )}
      <div className="left">
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
      </div>

      <div className="middle">
        <p onClick={() => window.open("https://www.waifuharem.club", "_self")}>
          HOME
        </p>
      </div>

      <div className="right">
        {isAuthenticated && (
          <div className="item-wrapper">
            <div
              className="item"
              onClick={() => {
                if (menuOpen === "profile") {
                  setmenuOpen(null);
                } else {
                  setmenuOpen("profile");
                }
              }}
            >
              <i className="fa-solid fa-align-right"></i>
            </div>
            {menuOpen === "profile" && (
              <div className="menu">
                <div className="user-info">
                  {blockchain.account ? (
                    <div className="wallet">
                      <div className="img">
                        <img src={mmLogo} alt="metamask logo" />
                      </div>
                      <p>MetaMask Connected</p>
                    </div>
                  ) : (
                    <div className="wallet">
                      <div className="img">
                        <img src={mmLogo} alt="metamask logo" />
                      </div>
                      <p>MetaMask Disconnected</p>
                    </div>
                  )}
                </div>

                <div
                  className="menu-item"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(connect());
                    setmenuOpen(null);
                  }}
                >
                  <i className="fa-solid fa-person-booth"></i>
                  <p>Connect Wallet</p>
                </div>
                <div
                  className="menu-item"
                  onClick={() => {
                    dispatch(setpopupMainStats(true));
                    setmenuOpen(null);
                  }}
                >
                  <i className="fa-solid fa-chart-column"></i>
                  <p>Statistics</p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {popupBg && <BgPopup />}
      {popupMainStats && <MainStatsPopup />}
    </div>
  );
};

export default Navbar;
