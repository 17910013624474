import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setpopupDt } from "../../redux/actions/styles";
import { setdetails } from "../../redux/actions/data";
import ButtonPrimary from "../button/ButtonPrimary";

const DtPopup = (props) => {
  const { selectedOption } = props;
  const dispatch = useDispatch();

  const { currentRoom, roomData } = useSelector((state) => state.data);

  const [name, setname] = useState(selectedOption ? roomData[currentRoom][`char${selectedOption}`].name : "");
  const [desc, setdesc] = useState(selectedOption ? roomData[currentRoom][`char${selectedOption}`].desc : "");

  return (
    <div className="dt-popup">
      <div className="bg-dark" onClick={() => dispatch(setpopupDt(false))} />

      <div className="container">
        <p className="title mb-3">Add Name and Description</p>
        <input placeholder="Name" value={name} onChange={(e) => setname(e.target.value)} />
        <input placeholder="Description" value={desc} onChange={(e) => setdesc(e.target.value)} />
        <div className="action">
          <ButtonPrimary text="Back" onClick={() => dispatch(setpopupDt(false))} />
          <ButtonPrimary
            text="Add"
            onClick={() => {
              dispatch(setdetails(currentRoom, selectedOption, name, desc));
              dispatch(setpopupDt(false));
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DtPopup;
