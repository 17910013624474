import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setpopupDes } from "../../redux/actions/styles";

const DescriptionPopup = (props) => {
  const { selectedOption } = props;
  const dispatch = useDispatch();

  const { currentRoom, roomData } = useSelector((state) => state.data);

  const [name] = useState(
    selectedOption ? roomData[currentRoom][`char${selectedOption}`].name : ""
  );
  const [desc] = useState(
    selectedOption ? roomData[currentRoom][`char${selectedOption}`].desc : ""
  );

  const [hearts] = useState(
    selectedOption ? roomData[currentRoom][`char${selectedOption}`].hearts : ""
  );

  return (
    <div className="des-popup">
      <div className="bg-dark" onClick={() => dispatch(setpopupDes(false))} />

      <div className="container ">
        <h1 className="mb-2 text-center">
          {name} <small className="text-xs text-center">({hearts})</small>
        </h1>
        <p className="text-center"> {desc}</p>
      </div>
    </div>
  );
};

export default DescriptionPopup;
