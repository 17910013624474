import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import RoomAdd from "./uis/RoomAdd";
import Characters from "./uis/Characters";
import ConnectWallet from "./uis/ConnectWallet";
import { fetchData } from "../../redux/data/dataActions";
import axios from "axios";
import { connect } from "../../redux/blockchain/blockchainActions";
import waifuService from "../../service/waifu.service";
import { setState } from "../../redux/actions/data";
import { setMenuOpen } from "../../redux/actions/data";

const Landing = () => {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const { balance, totalStaked } = useSelector((state) => state.blockchainData);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { currentRoom, roomData, menuOpen } = useSelector(
    (state) => state.data
  );

  // Replace with your Alchemy API key:
  const apiKey = "wh1yOF96xcS02w4Xs1_rnmhOINNTPImM";
  //change For Mainnet
  const baseURL = `https://eth-mainnet.g.alchemy.com/v2/${apiKey}/getNFTs/`;

  const contractAddr = "0xcC63542B0fc2131A6b0Ca593362B5224F7A9e024";

  let config = {
    method: "get",
    url: `${baseURL}?owner=${blockchain.account}&contractAddresses[]=${contractAddr}`,
  };

  let date = new Date();
  let dateString = date.getTime();

  let initialState = {
    waifus: false,
    currentRoom: "1",
    menuOpen: null,
    rooms: [{ id: "1", name: "Room 1" }],
    balances: [{ balance: 0, date: dateString }],
    roomData: {
      1: {
        background: "",
        char1: {
          id: 0,
          image: null,
          name: "",
          rarity: "",
          hearts: "",
          desc: "",
          audio: null,
          stakeTime: null,
        },
        char2: {
          id: 0,
          image: null,
          name: "",
          rarity: "",
          hearts: "",
          desc: "",
          audio: null,
          stakeTime: null,
        },
        char3: {
          id: 0,
          image: null,
          name: "",
          rarity: "",
          hearts: "",
          desc: "",
          audio: null,
          stakeTime: null,
        },
        char4: {
          id: 0,
          image: null,
          name: "",
          rarity: "",
          hearts: "",
          desc: "",
          audio: null,
          stakeTime: null,
        },
      },
    },
    addRoomCount: 1,
    account: "",
    id: "",
  };

  const getWaifus = async () => await waifuService.getWaifus();

  const createWaifu = async (waifu) => await waifuService.createWaifu(waifu);

  const updateWaifu = async (id, waifu) =>
    await waifuService.updateWaifu(id, waifu);

  const initiate = async () => {
    await getWaifus().then((data) => {
      let accountState = data.filter(
        (data) =>
          data.account &&
          data.account.toUpperCase() === blockchain.account.toUpperCase()
      );

      if (accountState.length > 0 || totalStaked > 0) {
        dispatch(setState(accountState[0]));
      } else {
        createState();
      }
    });
  };

  const createState = async () => {
    await createWaifu(initialState).then((data) => {
      updateWaifu(data, {
        id: data,
        account: blockchain.account.toUpperCase(),
      })
        .then((waifu) => console.log(waifu))
        .catch((err) => console.log(err));
    });
  };

  const getNFTs = () => {
    let tokens = [];
    // Make the request and print the formatted response:
    axios(config)
      .then((nfts) => {
        for (const nft of nfts.data.ownedNfts) {
          tokens.push(nft.id.tokenId);
        }
        console.log(tokens);
      })
      .catch((error) => console.log(error));
  };

  const claim = () => {
    blockchain.stakingContract.methods
      .claimReward(blockchain.account)
      .send({
        gasLimit: String(300000),
        from: blockchain.account,
      })
      .once("error", (err) => {
        console.log(err);
      })
      .then((receipt) => {
        getData();
        console.log(receipt);
      });
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.stakingContract !== null) {
      getNFTs();

      initiate();
      dispatch(fetchData(blockchain.account));
    }
  };

  useEffect(() => {
    dispatch(connect());
    console.log(balance);
    getData();
    // eslint-disable-next-line
  }, []);
  // eslint-disable-next-line
  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [blockchain.account]);

  return (
    <>
      {menuOpen == null ? (
        <div className="landing relative ">
          {roomData[currentRoom].background &&
          !roomData[currentRoom].background.includes(".mp4") ? (
            <img
              className="background"
              src={roomData[currentRoom].background}
              alt="background"
            />
          ) : (
            <video
              className="background"
              src={roomData[currentRoom].background}
              muted
              autoPlay
              loop
            />
          )}

          {isAuthenticated && <RoomAdd />}
          {isAuthenticated && <Characters />}
          {!isAuthenticated && <ConnectWallet />}

          <div
            className="background flex justify-center align-top items-center -mt-[250px]  absolute w-1/2 h-24  "
            style={{ zIndex: 50 }}
          >
            <div className=" w-1/2 h-90  p-4">
              <div className=" flex justify-around py-8 ">
                <p className="font-bold shadow-xl tracking-wider text-2xl uppercase ">
                  Staked: {totalStaked}
                </p>
                <p className="font-bold shadow-xl tracking-wider  text-2xl uppercase  ">
                  Balance: {(balance / 100000000000000).toFixed(2)}
                  <small class="text-xs font-light">2DC</small>
                </p>
              </div>
              <div className=" flex justify-around ">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    claim();
                  }}
                  className="bg-[#EC4899] px-8 p-2 rounded-md"
                >
                  Withdraw
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="landing relative "
          onClick={() => dispatch(setMenuOpen(null))}
        >
          {roomData[currentRoom].background &&
          !roomData[currentRoom].background.includes(".mp4") ? (
            <img
              className="background"
              src={roomData[currentRoom].background}
              alt="background"
            />
          ) : (
            <video
              className="background"
              src={roomData[currentRoom].background}
              muted
              autoPlay
              loop
            />
          )}

          {isAuthenticated && <RoomAdd />}
          {isAuthenticated && <Characters />}
          {!isAuthenticated && <ConnectWallet />}

          <div
            className="background flex justify-center align-top items-center -mt-[250px]  absolute w-1/2 h-24  "
            style={{ zIndex: 50 }}
          >
            <div className=" w-1/2 h-90  p-4">
              <div className=" flex justify-around py-8 ">
                <p className="font-bold shadow-xl tracking-wider text-2xl uppercase ">
                  Staked: {totalStaked}
                </p>
                <p className="font-bold shadow-xl tracking-wider  text-2xl uppercase  ">
                  Balance: {(balance / 100000000000000).toFixed(2)}
                  <small class="text-xs font-light">2DC</small>
                </p>
              </div>
              <div className=" flex justify-around ">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    claim();
                  }}
                  className="bg-[#EC4899] px-8 p-2 rounded-md"
                >
                  Withdraw
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Landing;
