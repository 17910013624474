import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "../layout/Navbar";
import Landing from "../pages/Landing";

const Router = () => {
  const isAuthenticated = true;

  useEffect(() => {
    function applyCursorRippleEffect(e) {
      const ripple = document.createElement("div");

      ripple.className = "ripple";
      document.body.appendChild(ripple);

      ripple.style.left = `${e.clientX}px`;
      ripple.style.top = `${e.clientY}px`;

      ripple.style.animation = "ripple-effect .4s  linear";
      ripple.onanimationend = () => document.body.removeChild(ripple);
    }

    document.onclick = (e) => applyCursorRippleEffect(e);
  }, []);
  return !isAuthenticated ? (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Landing />} />
      </Routes>
    </BrowserRouter>
  ) : (
    <BrowserRouter>
      <Navbar />
      <Routes>
        {/* <Route exact path="/" element={<Navigate to="/" replace />} /> */}
        <Route exact path="/" element={<Landing />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
